html,
#root,
.App,
body {
  height: 100%;
  margin: 0;
}
.Footer {
  position: absolute;
  z-index: 2;
  bottom: 15px;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
  padding: 5px 8px;
  line-height: 16px;
}
