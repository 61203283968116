.input {
  width: 100%;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid rgba(36, 35, 40, 0.3);
  box-shadow: none;
  font-size: 16px;
  height: 40px;
  width: 100%;
  margin: 2px auto 0;
  padding: 0 11px 0 11px;
  line-height: 1;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.input[disabled] {
  opacity: 0.3;
}

.closeButton {
  appearance: none;
  background-color: transparent;
  border: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  top: 4px;
  cursor: pointer;
}
.closeButton img {
  width: 16px;
  height: 16px;
  opacity: 0.5;
}

.resultList {
  background-color: #fff;
  border: 1px solid rgba(36, 35, 40, 0.3);
  border-radius: 5px;
  box-sizing: border-box;
  left: 0;
  line-height: 1;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 100%;
}

.resultItem {
  position: relative;
}

.resultItem + .resultItem:before {
  background-color: #d3d9e1;
  content: '';
  height: 1px;
  left: 12px;
  position: absolute;
  top: 0;
  width: calc(100% - 24px);
}

.resultItemLink {
  appearance: none;
  background-color: transparent;
  border: 0;
  display: block;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  padding: 16px 12px 14px;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.resultItemLink:focus {
  box-shadow: none;
  outline: none;
  background: #efefef;
}