/**
 * All sizes in em so you can control sizing with font-size
 */
.loader {
  display: block;
  position: relative;
  width: 1em;
  height: 1em;
  margin: 0 auto;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 0.8em;
  height: 0.8em;
  margin: 0.1em;
  border: 0.1em solid #f4364c;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f4364c transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
