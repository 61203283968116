.brandMap {
  position: relative;
  width: 100%;
  height: 100%;
}
.search {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  right: 20px;
}
.mapLayer {
  width: 100%;
  height: 100%;
}
.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px 0;
  background: rgba(255, 255, 255, 0.8);
}